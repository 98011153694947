import React from 'react'

import { Text, Link, Heading, Container, Card } from 'theme-ui'
import { Image, Box, Flex } from 'rebass'

// import styled, { css } from 'styled-components'
import styled from 'styled-components'

import Layout from '../components/layout'

import {
  BoxRel,
  ButtonBase,
  LinkBase,
  BlockHead,
  ExpertiseItem,
} from '../components/atoms'

import {
  SectionTitle,
  SectionInfo,
  TextBase,
  WorkCard,
} from '../components/blocks'

import SEO from '../components/seo'
import CTABlock from '../components/contact'
import Anima from '../components/anim'

const headerImage = require('../images/group_76.svg')
const designImage = require('../images/design.svg')
const strategyImage = require('../images/strategy.svg')
const trainingImage = require('../images/training.svg')
const developmentImage = require('../images/development.svg')
const caseImg = require('../images/demo_1.svg')

export const LinkArrow = styled(ButtonBase)`
  position: absolute;
  bottom: 0;
  &::after {
    position: absolute;
    content: '\00bb';
    transform: rotateZ(-270deg);
    margin-left: 10px;
  }
`

const BlockCentre = styled(Card)`
  background: #fff;
  // text-align: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
`

const Wideo = styled.video`
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0.3;
`

const Filler = styled(Box)`
  // background-image: url(https://i.giphy.com/media/3j0DAtKL86MeYZVRW0/giphy-downsized.gif);
  // background-size: cover;
`

const Blok = styled(Box)`
  width: 200px;
  height: 220px;
`
export const ContainerWrapper = styled(Container)`
  max-width: 100% !important;
  position: relative;
`

export const ContainerFull = (props) => {
  return (
    <ContainerWrapper variant={props.variant ? props.variant : 'styles.basic'}>
      <Container {...props} sx={{ borderBottom: 'none' }}>
        {props.children}
      </Container>
    </ContainerWrapper>
  )
}

const mobile = {
  s: 3,
  m: 4,
  l: 6,
}

const headingStyle = {}

export const query = graphql`
  query HomePageQuery {
    caseStudies: allSanityCaseStudy(
      limit: 3
      filter: { slug: { current: { ne: null } } }
    ) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              fixed(width: 400, height: 240) {
                width
                height
                src
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`

//

const HeroBlock = () => (
  <Box mb={5}>
    <ContainerFull variant="styles.bigCard">
      <BoxRel>
        <Flex flexWrap="wrap" mx={0}>
          <Box
            style={{ position: 'relative' }}
            width={[1, 6 / 12]}
            mx={0}
            p={0}
            pt={5}
            pb={4}
            pl={[4, 0, 0]}
          >
            <Text as="h1" sx={{ variant: 'text.big' }}>
              We help build great Products
            </Text>
            <Text
              sx={{
                variant: 'text.big2',
                maxWidth: '60%',
                lineHeight: 'heading',
              }}
            >
              Design and Engineering talent for fast moving teams.
            </Text>
            <Box pt={4}>
              <LinkArrow link to="case-studies">
                WHERE WE OFFER
              </LinkArrow>
            </Box>
          </Box>
          <Box width={[1, 5 / 12]} mx={0} p={0} pt={5} pr={5}>
            <Anima zoom={4}/>
            {/* <Image width={1} src={headerImage} /> */}
          </Box>
        </Flex>
      </BoxRel>
    </ContainerFull>
  </Box>
)

const ServiceBlock = ({ title, body }) => {
  return (
    <Card width={[1, 1, 3 / 12]} mx={3}>
      <Box>
        <Blok>
          
        </Blok>
        {/* <Image width="90px" src={designImage} mr={5} /> */}
        <Box>
          <TextBase variant="text.blockTitleCentre">{title}</TextBase>
          <TextBase variant="text.blockInfo">{body}</TextBase>
        </Box>
      </Box>
    </Card>
  )
}

const Services = () => (
  <ContainerFull variant="styles.bigCard" pb={7}>
    <Heading as="h3" variant="text.blockTitleCentre">
      Services we offer
    </Heading>
    <TextBase variant="text.blockInfoCenter">
      We are a full-stack product development studio <br />
      All the way from idea to launch!
    </TextBase>
    <Flex width={1} mt={5} flexDirection={['column', 'column', 'row']}>
      <ServiceBlock
        title="Design"
        body="Thoughtful, beautiful, user-centered design"
      />
      <ServiceBlock
        title="Development"
        body="Today’s technology, tested for tomorrow, built to last."
      />
      <ServiceBlock
        title="Support"
        body="In-depth, in-person training for your team."
      />
    </Flex>
  </ContainerFull>
)

const WorkListItems = ({ postEdges }) => {
  return (
    <ContainerFull variant="styles.bigCard" pb={7} pt={4}>
      {postEdges && (
        <Container>
          <Box pb={4}>
            <Heading as="h3" mb={1} variant="text.blockTitleCentre">
              Featured Works
            </Heading>
            <TextBase variant="text.blockInfoCenter">
              Some amazing projects our team got to work with
            </TextBase>
          </Box>
          <Flex
            sx={{
              listStyle: 'none',
              // display: ['flex', 'flex', 'flex'],
              // gridGap: 3,
              // gridTemplateColumns: 'repeat(auto-fit, minmax(256px, 1fr))',
            }}
          >
            {postEdges.map((post) => (
              <WorkCard post={post} />
              // <Card>
              //   <Box>
              //     <Image
              //       width={1}
              //       src={post.node.mainImage.asset.fixed.src}
              //       height={post.node.mainImage.asset.fixed.height}
              //       width={post.node.mainImage.asset.fixed.width}
              //     />
              //   </Box>
              //   <Box pt={3} style={{ position: 'relative' }}>
              //     {/* <Text mb={3}>Product Design / Product Developement</Text> */}
              //     <LinkBase to={`/projects/${post.node.id}`}>
              //       <TextBase variant="text.cardTitle">
              //         {post.node.title}
              //       </TextBase>
              //     </LinkBase>
              //   </Box>
              // </Card>
            ))}
          </Flex>
        </Container>
      )}
    </ContainerFull>
  )
}

const IndexPage = (props) => {
  const { data, errors } = props

  if (errors) throw errors

  const postEdges = (data && data.caseStudies && data.caseStudies.edges) || []

  return (
    <Layout>
      <SEO
        title={'Functionary Labs - Product Development Studio in Bangalore'}
        description=""
      />
      <HeroBlock />
      <Services />
      <WorkListItems postEdges={postEdges} />
      <CTABlock />
    </Layout>
  )
}

export default IndexPage
