import React from 'react'
import { Link } from 'gatsby'

import { Illustration, Cylinder, Ellipse, Shape } from 'react-zdog'
import { Box, Text } from 'rebass'

import { a, useSpring } from 'react-spring/zdog'

const Anima = ({ zoom }) => {
  const d1 = zoom * 5
  const d2 = zoom * 10
  const st = zoom * 1.5
  const st2 = zoom * 0.5
  return (
    <Box height="100%">
      <Illustration zoom={zoom} dragRotate={true}>
          <Cylinder
            diameter={d1}
            length={d2}
            stroke={false}
            color="#cf85dc"
            backface="#59a8f9"
          />
      </Illustration>
    </Box>
  )
}

export default Anima
